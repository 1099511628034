import {
  Icon,
  Teaser,
  TeaserContent,
  TeaserMeta,
  TeaserTitle,
} from "@whitespace/components";
import { useComponentSize } from "@whitespace/gatsby-hooks";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useRef } from "react";

import CalendarBadge from "../../../../components/CalendarBadge";

import * as styles from "./WPEventTeaser.module.css";

WPEventTeaser.propTypes = {
  className: PropTypes.string,
  contentNode: PropTypes.shape({
    broadcastType: PropTypes.string,
    content: PropTypes.node,
    date: PropTypes.string,
    excerpt: PropTypes.node,
    image: PropTypes.object,
    theme: PropTypes.string,
    title: PropTypes.node,
    show: PropTypes.object,
    acfGroupEvent: PropTypes.object,
    location: PropTypes.object,
    url: PropTypes.string,
    taxonomies: PropTypes.arrayOf(PropTypes.object),
  }),
  dateFormat: PropTypes.objectOf(PropTypes.string),
  // styles: PropTypes.objectOf(PropTypes.string),
};

export default function WPEventTeaser({
  className,
  contentNode,
  ...restProps
}) {
  let componentRef = useRef(null);

  let { width: componentWidth } = useComponentSize(componentRef);

  const {
    date,
    excerpt,
    title,
    show,
    url,
    location: { nodes },
  } = contentNode;
  const isCompact = componentWidth <= 572;
  const locationName = nodes[0]?.title;
  return (
    <div ref={componentRef}>
      <Teaser
        className={clsx(
          className,
          styles.teaser,
          isCompact && styles.isCompact
        )}
        link={{ url }}
        {...restProps}
      >
        <CalendarBadge className={styles.badge} date={date} locale={"sv"} />
        <TeaserContent className={clsx(styles.content)}>
          <TeaserTitle className={clsx(className, styles.teaserTitle)}>
            {show && <strong>{show.name}: </strong>}
            {title}
          </TeaserTitle>
          {nodes?.length === 1 && (
            <TeaserMeta>
              <Icon name="map-pin" className={clsx(styles.icon)} />
              <span className={clsx(styles.date)}>{locationName}</span>
            </TeaserMeta>
          )}
          {excerpt && <p className={clsx(styles.excerpt)}>{excerpt}</p>}
        </TeaserContent>
      </Teaser>
    </div>
  );
}
