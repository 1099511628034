import { Icon, Link, Button } from "@whitespace/components";
import { HTML } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import * as defaultStyles from "./EventInfo.module.css";

EventInfo.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
  info: PropTypes.objectOf(PropTypes.string),
  location: PropTypes.arrayOf(PropTypes.object),
};

export default function EventInfo({
  className,
  styles = defaultStyles,
  info,
  location,
}) {
  const { t } = useTranslation();

  const {
    bookingLink,
    eventLink,
    occasions,
    priceAdult,
    priceChildren,
    priceStudent,
    priceSenior,
    priceInformation,
    childrenAge,
    seniorAge,
  } = info;

  const formatDate = (start, end) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const eventStartDate = new Date(start.replace(/-/g, "/"));
    const eventEndDate = new Date(end.replace(/-/g, "/"));
    const startDate = start.substr(0, start.indexOf(" "));
    const endDate = end.substr(0, end.indexOf(" "));
    if (startDate != endDate) {
      return `${eventStartDate.toLocaleDateString(
        "sv-SE",
        options
      )} - <br/>${eventEndDate.toLocaleDateString("sv-SE", options)}`;
    } else {
      return `${eventStartDate.toLocaleDateString("sv-SE", options)}`;
    }
  };

  const formatTime = (start, end) => {
    const startTime = start.substr(start.indexOf(" "), start.length);
    const endTime = end.substr(end.indexOf(" "), end.length);
    if (startTime != endTime) {
      return `${startTime} - ${endTime}`;
    } else {
      return "--";
    }
  };

  return (
    <div className={clsx(styles.component, className)}>
      <div className={styles.inner}>
        <div className={styles.grid}>
          <div className={styles.gridItem}>
            <h3>{t("times")}</h3>
            <table className={styles.table}>
              <thead className={clsx(styles.listHeading)}>
                <tr className={styles.tr}>
                  <td className={styles.td}>{t("dates")}</td>
                  <td className={styles.td}>{t("time")}</td>
                </tr>
              </thead>
              <tbody className={styles.list}>
                {occasions?.map((occasion, index) => (
                  <tr className={styles.tr} key={index}>
                    <td className={styles.td}>
                      <HTML>
                        {formatDate(occasion.startDate, occasion.endDate)}
                      </HTML>
                    </td>
                    <td className={styles.td}>
                      {formatTime(occasion.startDate, occasion.endDate)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {(priceAdult || priceChildren || priceStudent || priceSenior) && (
            <div className={styles.gridItem}>
              <h3>{t("prices")}</h3>

              <table className={styles.table}>
                <thead className={clsx(styles.listHeading)}>
                  <tr className={styles.tr}>
                    <td className={styles.td}>{t("category")}</td>
                    <td className={styles.td}>{t("price")}</td>
                  </tr>
                </thead>
                {(priceAdult ||
                  priceChildren ||
                  priceSenior ||
                  priceStudent) && (
                  <tbody className={clsx(styles.list)}>
                    {priceAdult && (
                      <tr className={styles.tr}>
                        <td className={styles.td}>{t("adult")}</td>
                        <td className={styles.td}>{priceAdult}</td>
                      </tr>
                    )}
                    {priceChildren && (
                      <tr className={styles.tr}>
                        <td className={styles.td}>
                          {t("child")}{" "}
                          {childrenAge && (
                            <span>
                              ({t("childrenAge", { year: childrenAge })})
                            </span>
                          )}
                        </td>
                        <td className={styles.td}>{priceChildren}</td>
                      </tr>
                    )}
                    {priceSenior && (
                      <tr className={styles.tr}>
                        <td>
                          {t("senior")}{" "}
                          {seniorAge && (
                            <span>({t("seniorAge", { year: seniorAge })})</span>
                          )}
                        </td>
                        <td className={styles.td}>{priceSenior}</td>
                      </tr>
                    )}
                    {priceStudent && (
                      <tr className={styles.tr}>
                        <td className={styles.td}>{t("student")}</td>
                        <td className={styles.td}>{priceStudent}</td>
                      </tr>
                    )}
                  </tbody>
                )}
              </table>
              {priceInformation && <HTML>{priceInformation}</HTML>}
            </div>
          )}
          {eventLink && (
            <div className={styles.gridItem}>
              <h3>{t("links")}</h3>
              <Link to={eventLink}>{eventLink}</Link>
            </div>
          )}
          {location?.length === 1 && (
            <div className={clsx(styles.gridItem)}>
              <h3>{t("place")}</h3>
              <div className={styles.wrapper}>
                <Icon name="map-pin" className={clsx(styles.icon)} />
                <span className={clsx(styles.date)}>{location[0]?.title}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {bookingLink && (
        <div className={styles.bookingWrapper}>
          <Button
            url={bookingLink}
            className={clsx(styles.component, styles.button)}
          >
            {t("bookNow")}
          </Button>
        </div>
      )}
    </div>
  );
}
