import { useStaticQuery, graphql } from "gatsby";

export default function getCustomSiteMetaData() {
  return useStaticQuery(graphql`
    query SiteMeta {
      wp {
        acfOptionsThemeOptions {
          customSiteMeta {
            title
            description
            image {
              # base64: base64Uri
              src: sourceUrl(size: OPEN_GRAPH)
              # srcSet: srcSet(size: OPEN_GRAPH)
              width(size: OPEN_GRAPH)
              height(size: OPEN_GRAPH)
              # alt: altText
              # caption
              # credit
            }
          }
        }
      }
    }
  `).wp.acfOptionsThemeOptions;
}
