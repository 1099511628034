import * as defaultStyles from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleChildPageNav.module.css";
import {
  usePageChildren,
  usePageContext,
} from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import BoxNavigation from "./BoxNavigation";

ArticleChildPageNav.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function ArticleChildPageNav({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  let {
    contentNode: { id, title, revisionOf, pageSettings },
    isPreview,
  } = usePageContext();
  const showChildPagesNavigation = pageSettings?.showChildPagesNavigation;
  if (!showChildPagesNavigation) {
    return null;
  }

  // Corrects page children when previewing
  if (isPreview && revisionOf) {
    id = revisionOf.node.id;
  }

  let pageChildren = usePageChildren(id);
  return (
    <BoxNavigation
      className={clsx(styles.component, className)}
      items={pageChildren}
      title={title}
      {...restProps}
    />
  );
}
