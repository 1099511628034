import React, { Fragment } from "react";
import Helmet from "react-helmet";

import "./src/index.css";

export const wrapPageElement = ({ element }) => {
  return (
    <Fragment>
      <Helmet>
        <script
          src="https://app.octany.com/js/checkout.js?v=5"
          type="text/javascript"
        />
      </Helmet>
      {element}
    </Fragment>
  );
};

/**
 * Makes React Helmet elements come before Emotion’s style elements so that
 * Facebook can read the OG tags.
 */
export const onClientEntry = () => {
  // Select the node that will be observed for mutations
  const targetNode = document.head;

  // Options for the observer (which mutations to observe)
  const config = { attributes: false, childList: true, subtree: false };

  // Callback function to execute when mutations are observed
  const callback = (mutationList, observer) => {
    const insertBeforeNode = targetNode.getElementsByTagName("style")[0];
    for (const mutation of mutationList) {
      for (const node of mutation.addedNodes) {
        if (node.dataset.reactHelmet && !node.hasBeenMoved) {
          node.hasBeenMoved = true;
          if (insertBeforeNode) {
            targetNode.insertBefore(node, insertBeforeNode);
          }
        }
      }
    }
  };

  // Create an observer instance linked to the callback function
  const observer = new MutationObserver(callback);

  // Start observing the target node for configured mutations
  observer.observe(targetNode, config);

  // Later, you can stop observing
  // observer.disconnect();
};
