import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import { useHTMLProcessor } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/html-processor";

import getCustomSiteMetaData from "./customSiteMeta";
import { fallbackImage } from "./fallbackImage";
import { getSiteMetaData } from "./siteMeta";

export default function useOpenGraph(initialSeo) {
  let {
    contentNode: { openGraph, content, description, featuredImage, uri } = {},
  } = usePageContext();

  const siteMeta = getSiteMetaData();
  const { customSiteMeta } = getCustomSiteMetaData();
  const { processPageContent, stripHTML } = useHTMLProcessor();

  const processedContent = processPageContent({ content });

  if (!description) {
    if (typeof processedContent.preamble === "string") {
      description = processedContent.preamble;
    } else {
      description = stripHTML(content).slice(0, 300);
    }
  }

  if (!uri) {
    const pageContext = usePageContext();
    uri = pageContext?.contentType?.uri;
  }
  return {
    metaTitle: openGraph?.title ? openGraph.title : initialSeo.title,
    metaDescription: openGraph?.description
      ? openGraph.description
      : customSiteMeta?.description
      ? customSiteMeta.description
      : description,
    metaImage: openGraph?.image
      ? openGraph.image
      : featuredImage?.node
      ? featuredImage?.node
      : customSiteMeta.image
      ? customSiteMeta.image
      : fallbackImage(),
    metaUrl: siteMeta.siteUrl + uri,
  };
}
