import { Section } from "@jfrk/react-heading-levels";
import { ModularityArea } from "@municipio/gatsby-theme-basic/src/components";
import {
  withComponentDefaults,
  PageGrid,
  PageGridItem,
} from "@whitespace/components";
import ArticleMeta from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleMeta";
import ArticlePublishDate from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticlePublishDate";
import ArticleTitle from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleTitle";
import { layout } from "@whitespace/gatsby-theme-wordpress-basic/src/foundation";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import ArticleBroadcastChannel from "../../../../../../../projects/rott.se/src/components/ArticleBroadcastChannel";
import ArticleRelatedPosts from "../../../../components/ArticleRelatedPosts";
import ArticleBody from "../ArticleBody";
import ArticleSidebar from "../ArticleSidebar";
import ArticleTerms from "../ArticleTerms";

import * as defaultStyles from "./SplitArticleLayout.module.css";

SplitArticleLayout.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
  content: PropTypes.objectOf(PropTypes.string),
  children: PropTypes.node,
};

export default withComponentDefaults(SplitArticleLayout, "splitArticleLayout");

function SplitArticleLayout({
  className,
  styles = defaultStyles,
  content,
  ...restProps
}) {
  const {
    contentType,
    contentArea,
    contentAreaBottom,
    rightSidebar,
    sliderArea,
  } = content;
  const isEventPage = contentType === "event";
  return (
    <PageGrid className={clsx(styles.component, className)} {...restProps}>
      <PageGridItem>
        <article>
          <div className={layout.component}>
            <div className={styles.articleContentContainer}>
              <div className={clsx(styles.wrapper)}>
                <ModularityArea
                  area={sliderArea}
                  className={clsx(styles.sliderArea)}
                />
              </div>
              <div className={clsx(styles.wrapper)}>
                <div
                  className={clsx(
                    styles.header,
                    layout.component,
                    layout.componentWidthArticleFull
                  )}
                >
                  <ArticleTerms />
                  <ArticleTitle className={clsx(styles.title)} />
                  {contentType === "post" && (
                    <ArticlePublishDate className={styles.publishedDate} />
                  )}
                </div>
              </div>
              <Section>
                <div className={clsx(styles.wrapper)}>
                  <div
                    className={clsx(
                      styles.content,
                      layout.component,
                      layout.componentWidthArticleFull
                    )}
                  >
                    <ArticleBody
                      className={clsx(styles.articleBody)}
                      // className={clsx(!isEventPage && styles.articleBody)}
                    />
                    {contentType == "broadcast" && <ArticleBroadcastChannel />}
                    {!isEventPage && <ModularityArea area={contentArea} />}

                    <footer className={clsx(styles.footer)}>
                      <ArticleMeta />
                    </footer>

                    <ModularityArea
                      area={contentAreaBottom}
                      className={clsx(styles.contentAreaBottom)}
                    />
                  </div>
                  <ArticleSidebar
                    className={clsx(
                      layout.component,
                      layout.componentWidthArticleSidebar
                    )}
                  >
                    <ModularityArea
                      area={rightSidebar}
                      rightSidebar={rightSidebar}
                    />
                  </ArticleSidebar>
                </div>
                <div className={clsx(styles.wrapper)}>
                  <div
                    className={clsx(
                      layout.component,
                      layout.componentWidthArticleFull
                    )}
                  >
                    <ArticleRelatedPosts
                      className={clsx(styles.articleRelatedPosts)}
                    />
                  </div>
                </div>
              </Section>
            </div>
          </div>
        </article>
      </PageGridItem>
    </PageGrid>
  );
}
