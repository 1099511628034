// extracted by mini-css-extract-plugin
export var component = "HeroModule-module--component--bobbf";
export var content = "HeroModule-module--content--tWvGq";
export var cta = "HeroModule-module--cta--d0hNQ";
export var hasBackground = "HeroModule-module--hasBackground---trmj";
export var image = "HeroModule-module--image--OyVGI";
export var link = "HeroModule-module--link--sHO24";
export var linkIcon = "HeroModule-module--linkIcon--O+RNq";
export var linkWrapper = "HeroModule-module--linkWrapper--j5lOy";
export var links = "HeroModule-module--links--EZUIw";
export var reversed = "HeroModule-module--reversed--wVjzL";
export var title = "HeroModule-module--title--pvrA6";