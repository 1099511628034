// extracted by mini-css-extract-plugin
export var backdrop = "HeaderSearchDropdown-module--backdrop--aT-fQ";
export var component = "HeaderSearchDropdown-module--component--TNqkl";
export var dropdown = "HeaderSearchDropdown-module--dropdown--cmqED";
export var form = "HeaderSearchDropdown-module--form--1xs+B";
export var input = "HeaderSearchDropdown-module--input--BEw1s";
export var inputWrapper = "HeaderSearchDropdown-module--inputWrapper--Y3rGF";
export var label = "HeaderSearchDropdown-module--label--vPd+m";
export var searchIcon = "HeaderSearchDropdown-module--searchIcon--Y+r0q";
export var spinner = "HeaderSearchDropdown-module--spinner--jkagT";
export var toggler = "HeaderSearchDropdown-module--toggler--SrAYx";