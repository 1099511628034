import useSearch from "@whitespace/gatsby-plugin-search/src/hooks/useSearch";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import BounceLoader from "react-spinners/BounceLoader";

import SearchHits from "./SearchHits";
import * as defaultStyles from "./SearchResults.module.css";

SearchResults.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
};

export default function SearchResults({
  styles = defaultStyles,
  ...restProps
}) {
  const { t } = useTranslation();
  let { hits, isPending, error, isEmptySearch } = useSearch();
  const pageContext = usePageContext();
  const type = pageContext?.contentType?.enum;

  if (type === "EVENT") {
    hits = hits
      ?.filter((item) => new Date(item.date) >= new Date())
      .sort((a, b) => new Date(a.date) - new Date(b.date));
  }

  return (
    <div {...restProps}>
      {!isPending && !error && !isEmptySearch && !hits && (
        <div className={styles.spinner}>
          <BounceLoader color={"#de0b0b"} loading={isPending} size={80} />
        </div>
      )}
      {isEmptySearch && <div>{t("Enter search query…")}</div>}
      {error && (
        <div>{t("An error occurred while fetching search results")}</div>
      )}
      {(!hits || hits.length === 0) && isPending && (
        <div className={styles.spinner}>
          <BounceLoader color={"#de0b0b"} loading={isPending} size={80} />
        </div>
      )}
      {Array.isArray(hits) && hits.length === 0 && !isPending && (
        <div>{t("Found no search results")}</div>
      )}
      {hits && hits.length > 0 && <SearchHits hits={hits} />}
    </div>
  );
}
