import { H } from "@jfrk/react-heading-levels";
import { HTML } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import * as defaultStyles from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleTitle.module.css";
import { utilities } from "@whitespace/gatsby-theme-wordpress-basic/src/foundation";
import {
  usePageContext,
  usePageChildren,
} from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

ArticleTitle.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function ArticleTitle({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  let {
    contentNode: {
      title,
      isFrontPage,
      id,
      contentType: {
        node: { name: postType },
      },
      content,
    },
  } = usePageContext();

  let pageChildren = usePageChildren(id);

  if (postType == "page" && pageChildren.length && !content) return null;

  return (
    <H
      className={clsx(
        styles.component,
        className,
        (isFrontPage || (postType == "page" && !content)) &&
          utilities.visuallyHidden
      )}
      {...restProps}
    >
      <HTML>{title}</HTML>
    </H>
  );
}
