// extracted by mini-css-extract-plugin
export var badge = "WPEventCard-module--badge--Hnjzg";
export var cardContent = "WPEventCard-module--cardContent--mYkoZ";
export var component = "WPEventCard-module--component--q7ZLy";
export var date = "WPEventCard-module--date--aoGRB";
export var excerpt = "WPEventCard-module--excerpt--Ll4VY";
export var icon = "WPEventCard-module--icon--LHSch";
export var isCompact = "WPEventCard-module--isCompact--zI1Fo";
export var locationPlaceholder = "WPEventCard-module--locationPlaceholder--jDrKN";
export var mediaWrapper = "WPEventCard-module--mediaWrapper--sA3vX";
export var title = "WPEventCard-module--title--K0efB";