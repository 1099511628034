// extracted by mini-css-extract-plugin
export var bookingWrapper = "EventInfo-module--bookingWrapper--V4cEc";
export var button = "EventInfo-module--button--OD0sd";
export var component = "EventInfo-module--component--cVdKU";
export var grid = "EventInfo-module--grid--Zh8lN";
export var gridItem = "EventInfo-module--gridItem--TYi0W";
export var icon = "EventInfo-module--icon--+-lTy";
export var inner = "EventInfo-module--inner--DK7N+";
export var list = "EventInfo-module--list--A4zBu";
export var table = "EventInfo-module--table--ZsTKg";
export var td = "EventInfo-module--td--cd72R";
export var tr = "EventInfo-module--tr--2qQ1G";
export var wrapper = "EventInfo-module--wrapper--MXYSY";