import Grid from "@municipio/gatsby-theme-basic/src/components/Grid";
import { Card, CardContent, CardMeta, CardTitle } from "@whitespace/components";
import { useComponentSize } from "@whitespace/gatsby-hooks";
import { SearchTeaserMedia } from "@whitespace/gatsby-plugin-search";
import * as hitComponents from "@whitespace/gatsby-plugin-search/src/components/search-hits";
import { Time } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import clsx from "clsx";
import { camelCase, upperFirst } from "lodash-es";
import PropTypes from "prop-types";
import React, { useRef } from "react";

import * as defaultStyles from "./SearchHits.module.css";

SearchHits.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  hits: PropTypes.array.isRequired,
  dateFormat: PropTypes.objectOf(PropTypes.string),
};

function getComponentFromContentType(contentType) {
  let componentName =
    contentType && `${upperFirst(camelCase(contentType))}SearchHit`;
  return (
    // eslint-disable-next-line import/namespace
    (componentName && hitComponents[componentName]) ||
    hitComponents.DefaultSearchHit
  );
}

export default function SearchHits({
  styles = defaultStyles,
  hits,
  dateFormat = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  },
}) {
  let componentRef = useRef(null);
  let { width: componentWidth } = useComponentSize(componentRef);
  const isCompact = componentWidth <= 272;
  const contentType = hits?.[0].contentType;

  if (contentType === "event") {
    return (
      <Grid className={styles.list}>
        {hits.map((hit, index) => {
          const { text, image, label, url, date } = hit;
          return (
            <Card
              key={index}
              link={{ url }}
              className={clsx(styles.component, isCompact && styles.isCompact)}
            >
              {image && (
                <SearchTeaserMedia
                  image={{
                    ...image,
                    aspectRatio: 155 / 80,
                  }}
                />
              )}
              <CardContent>
                <CardTitle className={clsx(styles.title)}>{label}</CardTitle>
                {date && (
                  <CardMeta>
                    <Time
                      capitalize={true}
                      className={clsx(styles.date)}
                      date={date}
                      format={dateFormat}
                    />
                  </CardMeta>
                )}
                {text && <p className={clsx(styles.excerpt)}>{text}</p>}
              </CardContent>
            </Card>
          );
        })}
      </Grid>
    );
  }
  return (
    <ul className={styles.wrapper}>
      {hits.map((hit, index) => {
        const Component = getComponentFromContentType(hit.contentType);
        return <Component hit={hit} key={index} />;
      })}
    </ul>
  );
}
