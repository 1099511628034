// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-whitespace-gatsby-theme-wordpress-basic-src-pages-wp-preview-js": () => import("./../../../../../node_modules/@whitespace/gatsby-theme-wordpress-basic/src/pages/wp-preview.js" /* webpackChunkName: "component---node-modules-whitespace-gatsby-theme-wordpress-basic-src-pages-wp-preview-js" */),
  "component---node-modules-whitespace-gatsby-theme-wordpress-basic-src-templates-main-archive-template-js": () => import("./../../../../../node_modules/@whitespace/gatsby-theme-wordpress-basic/src/templates/MainArchiveTemplate.js" /* webpackChunkName: "component---node-modules-whitespace-gatsby-theme-wordpress-basic-src-templates-main-archive-template-js" */),
  "component---node-modules-whitespace-gatsby-theme-wordpress-basic-src-templates-month-archive-template-js": () => import("./../../../../../node_modules/@whitespace/gatsby-theme-wordpress-basic/src/templates/MonthArchiveTemplate.js" /* webpackChunkName: "component---node-modules-whitespace-gatsby-theme-wordpress-basic-src-templates-month-archive-template-js" */),
  "component---node-modules-whitespace-gatsby-theme-wordpress-basic-src-templates-year-archive-template-js": () => import("./../../../../../node_modules/@whitespace/gatsby-theme-wordpress-basic/src/templates/YearArchiveTemplate.js" /* webpackChunkName: "component---node-modules-whitespace-gatsby-theme-wordpress-basic-src-templates-year-archive-template-js" */),
  "component---packages-gatsby-theme-vansterpartiet-src-whitespace-gatsby-theme-wordpress-basic-pages-404-js": () => import("./../../../../../packages/gatsby-theme-vansterpartiet/src/@whitespace/gatsby-theme-wordpress-basic/pages/404.js" /* webpackChunkName: "component---packages-gatsby-theme-vansterpartiet-src-whitespace-gatsby-theme-wordpress-basic-pages-404-js" */),
  "component---packages-gatsby-theme-vansterpartiet-src-whitespace-gatsby-theme-wordpress-basic-templates-search-template-js": () => import("./../../../../../packages/gatsby-theme-vansterpartiet/src/@whitespace/gatsby-theme-wordpress-basic/templates/SearchTemplate.js" /* webpackChunkName: "component---packages-gatsby-theme-vansterpartiet-src-whitespace-gatsby-theme-wordpress-basic-templates-search-template-js" */),
  "component---packages-gatsby-theme-vansterpartiet-src-whitespace-gatsby-theme-wordpress-basic-templates-single-template-js": () => import("./../../../../../packages/gatsby-theme-vansterpartiet/src/@whitespace/gatsby-theme-wordpress-basic/templates/SingleTemplate.js" /* webpackChunkName: "component---packages-gatsby-theme-vansterpartiet-src-whitespace-gatsby-theme-wordpress-basic-templates-single-template-js" */),
  "component---packages-local-gatsby-plugin-wordpress-taxonomy-src-templates-term-template-js": () => import("./../../../../../packages/local-gatsby-plugin-wordpress-taxonomy/src/templates/TermTemplate.js" /* webpackChunkName: "component---packages-local-gatsby-plugin-wordpress-taxonomy-src-templates-term-template-js" */)
}

