import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import * as defaultStyles from "./CodeModule.module.css";

CodeModule.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.any,
  module: PropTypes.any,
};

export default function CodeModule({
  styles = defaultStyles,
  className,
  title,
  module: { modCodeOptions: { code } = {} },
  ...restProps
}) {
  return (
    <ModuleWrapper
      as={"div"}
      title={title}
      styles={styles}
      className={clsx(styles.component, className)}
      {...restProps}
    >
      <div
        className={clsx(styles.wrapper)}
        dangerouslySetInnerHTML={{
          __html: code,
        }}
      />
    </ModuleWrapper>
  );
}
