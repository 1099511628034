import { css } from "@emotion/react";
import ListPostsModuleItem from "@municipio/gatsby-theme-basic/src/components/modularity-modules/posts-modules/ListPostsModuleItem";
import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import { Link, Button, Icon, RoundIcon } from "@whitespace/components";
import clsx from "clsx";
import { kebabCase } from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import useArchiveLink from "../../../../../hooks/useArchiveLink";
import RuledList from "../../RuledList";

import * as defaultStyles from "./ListPostsModule.module.css";

ListPostsModule.propTypes = {
  className: PropTypes.string,
  module: PropTypes.shape({
    modPostsDataDisplay: PropTypes.shape({
      postsFields: PropTypes.objectOf(PropTypes.string),
      theme: PropTypes.string,
    }),
    modPostsDataSource: PropTypes.shape({
      postsDataPostType: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  limit: PropTypes.number,
  normalizedItems: PropTypes.arrayOf(PropTypes.object),
  styles: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.any,
};

export default function ListPostsModule({
  styles = defaultStyles,
  className,
  title,
  module,
  limit = 4,
  normalizedItems,
  ...restProps
}) {
  const { t } = useTranslation();
  const [shownItems, setShownItems] = useState(limit);
  const {
    modPostsDataDisplay: { theme },
  } = module;

  const postType = module?.modPostsDataSource?.postsDataPostType?.name;
  const hasMoreItems = normalizedItems.length - 1 >= shownItems;

  const {
    archiveLinkLabel,
    archiveLinkUri,
    archiveLinkTarget,
    showArchiveLink,
  } = useArchiveLink();

  return (
    <ModuleWrapper
      title={title}
      {...restProps}
      className={clsx(styles.component, theme, styles[postType], className)}
      css={css({
        "--list-rule-color": theme
          ? `var(--brand-color-${kebabCase(theme)})`
          : "var(--color-divider)",
        "--module-wrapper-title-rule-color": theme
          ? `var(--brand-color-${kebabCase(theme)})`
          : "var(--color-divider)",
      })}
    >
      <RuledList
        className={clsx(styles.list, hasMoreItems && styles.hasMoreItems)}
        ruleTop={postType !== "broadcast"}
        ruleBottom={postType !== "broadcast"}
        noRule={postType == "broadcast"}
      >
        {normalizedItems.slice(0, shownItems).map((item, index) => {
          return (
            <ListPostsModuleItem
              item={item}
              className={clsx(styles.item)}
              key={index}
            />
          );
        })}
      </RuledList>
      {(hasMoreItems || !!showArchiveLink) && (
        <div className={styles.footer}>
          {hasMoreItems && (
            <Button
              className={clsx(styles.toggler)}
              onClick={() => setShownItems(shownItems + limit)}
            >
              {t("Show more")}
              <Icon name={"caret-up"} className={styles.togglerIcon} />
            </Button>
          )}
          {!!showArchiveLink && !hasMoreItems && (
            <Link
              className={styles.archiveLink}
              to={archiveLinkUri}
              target={archiveLinkTarget}
            >
              {archiveLinkLabel}
              <RoundIcon
                className={styles.archiveLinkIcon}
                name={"arrow-right-2"}
              />
            </Link>
          )}
        </div>
      )}
    </ModuleWrapper>
  );
}
